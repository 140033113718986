import axios from "axios"

import { BlogLiteralCategory } from "../../types/website-pages"

import { getLegacyPost, getLegacySlugs, PaginatedLegacyItem } from "./static-content"
import { removeDuplicates, removeDuplicatesBySlug } from "./utils"

const WP_BASE_PATH = process.env.NEXT_PUBLIC_WORDPRESS_BLOG

export type WordPressPost = {
  author: number
  categories: number[]
  content: {
    protected: boolean
    rendered: string
  }
  date: string
  date_gmt: string
  excerpt: {
    protected: boolean
    rendered: string
  }
  featured_media: number
  guid: {
    rendered: string
  }
  id: number
  link: string
  modified: string
  modified_gmt: string
  slug: string
  status: string
  tags: string[]
  title: {
    rendered: string
  }
  type: string
  yoast_head_json: {
    author: string
    description: string
    og_image?: [
      {
        url: string
      }
    ]
    robots: {
      follow: "follow" | "nofollow"
      index: "index" | "noindex"
    }
    title: string
  }
}

const postTypeMapping: Record<Locale, string> = {
  en: "english_post",
  fr: "french_post",
  it: "posts",
}

export const categoryToCategoryId = {
  "customer-stories": 4,
  "home-buying": 5,
  "home-selling": 6,
  news: 3,
  "partner-stories": 2,
  tips: 7,
} as const

export type Category = keyof typeof categoryToCategoryId
export type CategoryID = (typeof categoryToCategoryId)[Category]

export const categoryIdToCategory = Object.entries(categoryToCategoryId).reduce(
  (acc, [category, categoryId]) => {
    return {
      ...acc,
      [categoryId]: category,
    }
  },
  {} as Record<CategoryID, Category>
)

export const getAllWpPosts = async (locale: Locale): Promise<WordPressPost[]> => {
  try {
    const postLanguage = postTypeMapping[locale] || postTypeMapping.it
    const validPages = await axios.get(`${WP_BASE_PATH}/wp-json/wp/v2/${postLanguage}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return validPages.data
  } catch (err) {
    console.error("Error fetching WordPress posts:", err)
    return []
  }
}

const getWpPostsBySlug = async (slug: string, locale: Locale): Promise<WordPressPost[]> => {
  try {
    const postLanguage = postTypeMapping[locale] || postTypeMapping.it
    const validPages = await axios.get(`${WP_BASE_PATH}/wp-json/wp/v2/${postLanguage}?slug=${slug}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return validPages.data
  } catch (err) {
    console.error("Error fetching WordPress posts:", err)
    return []
  }
}

export const getPageById = async (id: number): Promise<WordPressPost> => {
  const validPages = await axios.get(`${WP_BASE_PATH}/wp-json/wp/v2/pages/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  })

  return validPages.data
}

export const getWpPostsByCategoryList = async (
  categories: Category[],
  locale: Locale
): Promise<WordPressPost[]> => {
  try {
    const postLanguage = postTypeMapping[locale] || postTypeMapping.it
    const categoriesList = categories.map((category) => categoryToCategoryId[category]).join(",")
    const response = await axios.get(
      `${WP_BASE_PATH}/wp-json/wp/v2/${postLanguage}?categories=${categoriesList}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    return response.data
  } catch (err) {
    console.error("Error fetching WordPress posts:", err)
    return []
  }
}

const getWpPostsByCategory = async (category: string, locale: Locale): Promise<WordPressPost[]> => {
  try {
    const postLanguage = postTypeMapping[locale] || postTypeMapping.it
    const response = await axios.get(
      `${WP_BASE_PATH}/wp-json/wp/v2/${postLanguage}?categories=${
        categoryToCategoryId[category as Category]
      }`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    return response.data
  } catch (err) {
    console.error("Error fetching WordPress posts:", err)
    return []
  }
}

function isLegacyPostItem(post: PaginatedLegacyItem | WordPressPost): post is PaginatedLegacyItem {
  return (post as PaginatedLegacyItem).postType === "LEGACY"
}

async function fetchPaginatedPosts(post: PaginatedLegacyItem | WordPressPost, locale: Locale) {
  if (!isLegacyPostItem(post)) return post
  const legacyPost = await getLegacyPost(post.slug, locale)
  return legacyPost[0]
}

export const fetchPosts = async (
  page: number,
  pageSize: number,
  locale: Locale,
  category?: BlogLiteralCategory
): Promise<{ items: WordPressPost[]; totalCount: number }> => {
  const offset = (page - 1) * pageSize
  const endOffset = page * pageSize
  try {
    const wpPosts = category ? await getWpPostsByCategory(category, locale) : await getAllWpPosts(locale)
    const legacySlugs = await getLegacySlugs(locale, category)

    const allSortedPosts = removeDuplicatesBySlug([...wpPosts, ...legacySlugs]).sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    )
    const totalCount = allSortedPosts.length
    const slicedSortedPosts = allSortedPosts.slice(offset, endOffset)
    const items = await Promise.all(slicedSortedPosts.map(async (pag) => fetchPaginatedPosts(pag, locale)))

    return {
      items,
      totalCount,
    }
  } catch (err) {
    console.error("Error combining posts:", err)
    return {
      items: [],
      totalCount: 0,
    }
  }
}

export async function getPost(slug: string, locale: Locale): Promise<WordPressPost[]> {
  const wpPost = await getWpPostsBySlug(slug, locale)
  if (wpPost.length > 0) return wpPost
  const legacyPost = await getLegacyPost(slug, locale)
  if (legacyPost.length > 0) return legacyPost
  return []
}
