import { BlogLiteralCategory } from "@app/shared/types/website-pages"
import slugBlacklist from "@shared/blog/slug-blacklist.json"

import { WordPressPost } from "./wordpress-blog"

type PaginatedSlug = {
  date: string
  slug: string
  tags: string[]
  title: string
}

export type PaginatedLegacyItem = PaginatedSlug & { postType: "LEGACY" }

slugBlacklist as string[]
export async function getLegacySlugs(
  locale: Locale,
  category?: BlogLiteralCategory
): Promise<PaginatedLegacyItem[]> {
  const posts = await import(`@shared/blog/${category || "all-posts"}.json`)

  const allPosts = posts.default[locale] as PaginatedSlug[]
  return allPosts
    .filter((item) => !slugBlacklist.includes(item.slug))
    .map((item) => ({ ...item, postType: "LEGACY" }))
}

export const getLegacyPost = async (slug: string, locale: Locale): Promise<WordPressPost[]> => {
  try {
    const post = await import(`@shared/blog/posts/${locale}/${slug}.json`)
    return [post.default]
  } catch (err: any) {
    console.error(`error importing JSON: ${locale}/${slug} file: ${err}`)
    return []
  }
}

export const removeBlacklistedSlugs = (slugs: string[]): string[] => {
  return slugs.filter((slug) => !slugBlacklist.includes(slug))
}
