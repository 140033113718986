import { Body, H2 } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import React, { useEffect, useState } from "react"

import { Category } from "@app/components/careers/open-positions/categories"
import theme from "@app/shared/design-lib/style-theme/theme.css"
import { BlogCategoryType } from "@app/shared/types/website-pages"

import { ArticleCardProps } from "../types/article-card"
import { CategoryID, categoryIdToCategory } from "../utils/blog/wordpress-blog"

import { articleCard, figure, h2, height, image, span, textWrapper } from "./ArticleCard.css"

export enum ArticleDimension {
  Small,
  Medium,
  Large,
}

function truncateText(text: string = "", wordLimit: number) {
  const words = text.split(" ")
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "..."
  }
  return text
}

function isLarge(dimension: ArticleDimension, width: number | undefined) {
  return dimension === ArticleDimension.Large && width !== undefined && width > 568
}

export const ArticleCard: React.FC<{
  dimension: ArticleDimension
  props: ArticleCardProps
  trimAt?: number
}> = ({ dimension, props, trimAt = 10 }) => {
  const { t } = useTranslation()

  const [textPreview, setTextPreview] = useState<string | null>(null)

  useEffect(() => {
    const preview =
      props.description && props.description.trim() !== ""
        ? truncateText(props.description, trimAt)
        : truncateText(props.articlePreview, trimAt)
    setTextPreview(preview)
  }, [])

  const categoryStrings: (keyof BlogCategoryType)[] = props.category.map(function (category) {
    return categoryIdToCategory[category as CategoryID] as keyof BlogCategoryType
  })

  return (
    <article className={articleCard({ isLarge: isLarge(dimension, undefined) })}>
      <a aria-label={props.title} href={props.link}>
        <figure className={figure}>
          <img alt={props.imageAltText || ""} className={image} src={props.imageSrc} />
        </figure>
        <div className={height}>
          <div style={{ height: "100%" }}>
            <Body noMargin size="s">
              {categoryStrings[0]
                ? `${t(`blog:categories.${categoryStrings[0]}`)} \u00B7 ${props.publicationDate}`
                : props.publicationDate}
            </Body>
            <h2
              dangerouslySetInnerHTML={{
                __html: props.title,
              }}
              className={h2}
            ></h2>
            <Body noMargin size="m">
              {textPreview && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: textPreview,
                  }}
                />
              )}
            </Body>
          </div>
          <div className={textWrapper}>
            <Body noMargin size="s">
              {props.continueReadingCtaText}
            </Body>
            <span className={span}>
              <svg fill="none" height="10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path
                  clipRule="evenodd"
                  d="M5.41421 0.292847L13.1213 7.99995L5.41421 15.7071L4 14.2928L10.2921 7.99995L4 1.70706L5.41421 0.292847Z"
                  fill={theme.colors.greyscale600}
                  fillRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
      </a>
    </article>
  )
}
