import React, { DetailedHTMLProps } from "react"

import theme from "@app/shared/design-lib/style-theme/theme.css"

import { inner, pageSection } from "./styles/pageSection.css"

type space = keyof typeof theme.spacings

interface Props extends DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  background?: string
  children: React.ReactNode
  hasPadding?: boolean
  id?: HTMLElement["id"]
  marginBottom?: space
  marginTop?: space
  size?: "small" | "large" | "full"
}

export const PageSection: React.FC<Props> = ({
  background = "#fff",
  children,
  hasPadding = false,
  marginTop = "2xl",
  size = "small",
  ...props
}) => {
  return (
    <section
      className={pageSection({
        background: background as "transparent" | undefined,
        hasPadding,
        marginTop: marginTop as "xl" | "xs" | undefined,
      })}
      {...props}
    >
      <div className={inner({ size })}>{children}</div>
    </section>
  )
}
